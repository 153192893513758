<template>
  <div>
    <PurchaseDetails
      v-if="showQ"
      :vendors="vendors"
      :reqItem="currentItem"
      :groupItems="groupItems"
      :rights="rights"
      :getStaff="getStaff"
      :getProduct="getProduct"
      @close-comp="closeComp"
    />
    <!-- @load-data="LoadData"
      @load-supp="LoadSupplier" -->

    <b-card v-else>
      <b-table
        class=""
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        small
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="d-flex justify-content-center my-2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(staff)="data">
          <b-badge variant="light-primary">
            {{ data.item.tID }}
          </b-badge>
        </template>

        <template #cell(product)="data">
          <b-badge variant="light-primary">
            {{ data.item.productID }}
          </b-badge>
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-button
            variant="primary"
            class="btn-icon mr-50"
            v-b-tooltip.hover.left
            title="View Quotation"
            @click="showComp(data.item)"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>

          <b-button
            variant="outline-primary"
            class="btn-icon"
            v-b-tooltip.hover.left
            title="Purchase Order"
            @click="print(data.item.id)"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";
import PurchaseDetails from "./purchaseDetails.vue";

export default {
  components: {
    PurchaseDetails,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      rights: {},
      dataLoading: false,
      allItems: [],
      items: [],
      fields: [
        { label: "groupID", key: "groupID" },
        { label: "staff", key: "staff" },
        { label: "date", key: "date" },
        { label: "product", key: "product" },
        { label: "quantity", key: "quantity" },
        { label: "note", key: "note" },
        { label: "status", key: "status" },
        // { label: "meta_count", key: "meta_count" },
        { key: "actions", label: "actions" },
      ],
      products: [],
      staffList: [],
      vendors: [],
      currentItem: {},
      groupItems: [],
      showQ: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    print(id) {
      let url =
        `https://${this.reportDomain}.myskool.app/Inventory/Order?dbb=` +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&reqID=" +
        id +
        "&userID=" +
        this.$store.state.userData.userID;

      window.open(url, "_blank");
    },
    getProduct(id) {
      let obj = this.products.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    getStaff(id) {
      let obj = this.staffList.find((el) => el.id == id);
      return obj ? obj.name : "";
    },

    showComp(val) {
      this.currentItem = val;
      if (val.groupID > 0) {
        this.groupItems = this.allItems.filter(
          (el) => el.groupID == val.groupID && el.id != val.id
        );
      } else this.groupItems = [];
      this.showQ = true;
      // console.log(this.groupItems);
    },
    closeComp() {
      this.showQ = false;
      this.currentItem = {};
      this.groupItems = [];
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "requisition/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.allItems = res;
      // for dummy groupID
      this.allItems.forEach((el) => {
        if (el.id % 2 == 0) el.groupID = 2;
        else el.groupID = 0;
      });
      this.items = res;

      this.dataLoading = false;

      // console.log(this.items);
    },
  },
};
</script>
<style></style>
