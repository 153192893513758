<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Quotation</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group invalid-feedback="Vendor is required." ref="vID">
              <div class="d-flex justify-content-between">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Vendor</label
                >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="18"
                  v-b-tooltip.hover.top
                  title="Manage Vendor"
                  @click="openSupp()"
                />
              </div>
              <v-select
                v-model="qObj.vendorID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vendors"
                :reduce="(opt) => opt.id"
                label="name"
                :clearable="false"
                placeholder="Select vendor"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="qObj.date"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required."
              ref="amount"
            >
              <b-form-input
                type="number"
                v-model="qObj.amount"
                placeholder="Enter amount"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required"
              ref="qnote"
            >
              <b-form-textarea
                placeholder="Enter note"
                v-model.trim="qObj.note"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Attachments</label
              >
            </div>
            <div class="d-flex flex-wrap">
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
                style="width: 110px; height: 96px"
                @click="!mydocloading && $refs.psamplefile.click()"
              >
                <b-spinner
                  v-if="mydocloading"
                  small
                  label="Loading..."
                  variant="primary"
                />
                <feather-icon
                  v-else
                  icon="PlusIcon"
                  size="34"
                  class="text-primary"
                />
              </div>
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
                style="width: 110px; height: 96px; position: relative"
                v-for="(file, index) in attachList"
                :key="index"
                @click="openFile(file)"
              >
                <b-avatar
                  size="20"
                  variant="primary"
                  style="position: absolute; top: -5px; right: -5px"
                >
                  <feather-icon
                    icon="XIcon"
                    @click.stop="deleteDocument(index)"
                  />
                </b-avatar>
                <feather-icon
                  :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
                  size="30"
                  class="text-primary"
                />
              </div>

              <input
                type="file"
                id="psamplefile"
                hidden
                ref="psamplefile"
                multiple
                @change="PsampleAdd()"
              />
            </div>
          </b-col>

          <b-table :items="qList" :fields="qFields" small class="mt-1">
            <template #cell(product)="data">
              {{ getProduct(data.item.productID) }}
            </template>
            <template #cell(qty)="data">
              <b-form-input
                type="number"
                v-model="data.item.qty"
                placeholder="Enter quantity"
              >
              </b-form-input>
            </template>
            <template #cell(unitPrice)="data">
              <b-form-input
                type="number"
                v-model="data.item.unitPrice"
                placeholder="Enter amount"
              >
              </b-form-input>
            </template>
          </b-table>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              @click="saveQuo()"
              :disabled="savingQ"
            >
              <b-spinner v-if="savingQ" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-supplier"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Vendor</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Vendor name is required."
            >
              <b-form-input
                ref="sname"
                v-model.trim="sObj.name"
                placeholder="Enter vendor name here"
                @focusout="checkSuppName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Contact"
              invalid-feedback="Contact is required."
            >
              <vue-tel-input
                v-model="sObj.contact"
                :dropdownOptions="dropOptions"
              ></vue-tel-input>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              @click="saveSupp()"
              :disabled="savingSupp"
              block
            >
              <b-spinner v-if="savingSupp" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-2"
          show-empty
          :items="vendors"
          :fields="spFields"
          responsive
          hover
          small
        >
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editSupp(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteSupp(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Approve Quotation</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required"
              ref="q_app"
            >
              <b-form-textarea
                placeholder="Enter note"
                v-model.trim="qDetails.approvalNote"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              block
              @click="approveQ()"
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Approve</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <h3 class="mb-1">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-secondary"
        class="btn-icon mr-50"
        @click="$emit('close-comp')"
      >
        <feather-icon icon="ChevronLeftIcon" />
        Back
      </b-button>
      Quotations
    </h3>
    <b-card>
      <b-row>
        <b-col xl="2" lg="3" md="4" v-if="items.length > 0">
          <b-button
            @click="print()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :disabled="dataLoading"
          >
            <feather-icon icon="PrinterIcon" class="mr-50" />
            <span class="align-middle">Purchase Order</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        class="mt-2"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="d-flex justify-content-center my-2">
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(vendor)="data">
          <b-badge variant="light-primary">
            {{ getVendor(data.item.vendorID) }}
          </b-badge>
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #cell(amount)="data">
          <b-badge variant="light-primary">
            {{ data.item.amount }}
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <!-- <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-50"
              v-b-tooltip.hover.left
              title="Edit"
              @click="openQuo(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <template
              v-if="
                data.item.status == 'pending' &&
                !items.find((el) => el.status == 'approved')
              "
            >
              <b-button
                v-if="rights.approve"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                v-b-tooltip.hover.bottomleft
                title="Approve"
                @click="openQApprove(data.item)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="rights.delete"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                class="btn-icon"
                v-b-tooltip.hover.bottomright
                title="Delete"
                @click="DeleteQ(data.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
          </div>
        </template> -->
      </b-table>
    </b-card>

    <h3 class="mb-1">Requisition</h3>
    <b-card>
      <b-row>
        <b-col md="6">
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Teacher:</h5>
            <span> {{ getStaff(reqItem.tID) }}</span>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Product:</h5>
            <span> {{ getProduct(reqItem.productID) }}</span>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Quantity:</h5>
            <span> {{ reqItem.quantity }}</span>
          </div>
        </b-col>
        <b-col md="6">
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Date:</h5>
            <b-badge variant="light-primary">
              {{
                new Date(reqItem.date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Note:</h5>
            <span> {{ reqItem.note }}</span>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Status:</h5>
            <b-badge variant="light-primary">{{ reqItem.status }}</b-badge>
          </div>
          <div
            class="d-flex align-items-center mb-50"
            v-if="reqItem.status != 'pending'"
          >
            <h5 class="font-weight-bolder mb-0 mr-50">
              {{
                reqItem.status == "approved"
                  ? "Approval Note:"
                  : "Rejection Reason:"
              }}
            </h5>
            <span>
              {{
                reqItem.status == "approved"
                  ? reqItem.approvalNote
                  : reqItem.rejectionNote
              }}</span
            >
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-for="(item, ind) in groupItems" :key="ind">
      <b-row>
        <b-col md="6">
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Teacher:</h5>
            <span> {{ getStaff(item.tID) }}</span>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Product:</h5>
            <span> {{ getProduct(item.productID) }}</span>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Quantity:</h5>
            <span> {{ item.quantity }}</span>
          </div>
        </b-col>
        <b-col md="6">
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Date:</h5>
            <b-badge variant="light-primary">
              {{
                new Date(item.date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Note:</h5>
            <span> {{ item.note }}</span>
          </div>
          <div class="d-flex align-items-center mb-50">
            <h5 class="font-weight-bolder mb-0 mr-50">Status:</h5>
            <b-badge variant="light-primary">{{ item.status }}</b-badge>
          </div>
          <div
            class="d-flex align-items-center mb-50"
            v-if="item.status != 'pending'"
          >
            <h5 class="font-weight-bolder mb-0 mr-50">
              {{
                item.status == "approved"
                  ? "Approval Note:"
                  : "Rejection Reason:"
              }}
            </h5>
            <span>
              {{
                item.status == "approved"
                  ? item.approvalNote
                  : item.rejectionNote
              }}</span
            >
          </div>
        </b-col>
      </b-row>
    </b-card>

    <h3 class="mb-0">
      Invoice
      <b-button
        class="btn-icon ml-1"
        variant="primary"
        v-b-tooltip.hover.top
        title="Upload"
        :disabled="uploading"
        @click="$refs.inv.click()"
      >
        <b-spinner v-if="uploading" small type="grow" />
        <feather-icon v-else icon="UploadIcon" />

        <input
          type="file"
          id="inv"
          hidden
          ref="inv"
          multiple
          accept="image/*"
          @change="uploadInv()"
        />
      </b-button>
    </h3>
    <h4 v-if="invList.length == 0" class="text-center my-1">
      No records found
    </h4>
    <div class="d-flex flex-wrap my-1" v-else>
      <div
        class="cursor-pointer rounded mr-1 mb-1"
        style="width: 110px; height: 96px; position: relative"
        v-for="(file, index) in invList"
        :key="index"
        @click="openFile(file)"
      >
        <b-avatar
          size="20"
          variant="primary"
          style="position: absolute; top: -5px; right: -5px"
          button
          @click.stop="deleteInv(index)"
        >
          <feather-icon icon="XIcon" />
        </b-avatar>
        <b-img :src="file" class="w-100 h-100 rounded" />
      </div>
    </div>

    <h3 class="mb-0">
      Product Images
      <b-button
        class="btn-icon ml-1"
        variant="primary"
        v-b-tooltip.hover.top
        title="Upload"
        :disabled="itemLoading"
        @click="$refs.pItems.click()"
      >
        <b-spinner v-if="itemLoading" small type="grow" />
        <feather-icon v-else icon="UploadIcon" />

        <input
          type="file"
          id="pItems"
          hidden
          ref="pItems"
          multiple
          accept="image/*"
          @change="uploadPItems()"
        />
      </b-button>
    </h3>
    <h4 v-if="pItemsList.length == 0" class="text-center my-1">
      No records found
    </h4>
    <div class="d-flex flex-wrap my-1" v-else>
      <div
        class="cursor-pointer rounded mr-1 mb-1"
        style="width: 110px; height: 96px; position: relative"
        v-for="(file, index) in pItemsList"
        :key="index"
        @click="openFile(file)"
      >
        <b-avatar
          size="20"
          variant="primary"
          style="position: absolute; top: -5px; right: -5px"
          button
          @click.stop="deletePItems(index)"
        >
          <feather-icon icon="XIcon" />
        </b-avatar>
        <b-img :src="file" class="w-100 h-100 rounded" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    this.LoadData();
  },
  props: {
    reqItem: {
      type: Object,
      required: true,
    },
    groupItems: {
      type: Array,
      required: true,
    },
    vendors: {
      type: Array,
      required: true,
    },
    rights: {
      type: Object,
      required: true,
    },
    getStaff: {
      type: Function,
      required: true,
    },
    getProduct: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      savingQ: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      dataLoading: false,
      items: [],
      fields: [
        { label: "vendor", key: "vendor" },
        { label: "date", key: "date" },
        { label: "amount", key: "amount" },
        { label: "note", key: "note" },
        { label: "status", key: "status" },
        // { key: "actions", label: "actions" },
      ],
      qDetails: {},
      visibility3: false,
      visibility4: false,
      visibility5: false,
      qObj: {},
      mydocloading: false,
      attachList: [],
      request: false,
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      sObj: {
        id: 0,
        name: "",
        contact: "",
        campusID: this.$store.state.userData.cId,
      },
      savingSupp: false,
      spFields: [
        { label: "name", key: "name" },
        { label: "contact", key: "contact" },
        { key: "actions", label: "actions" },
      ],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      qList: [],
      qFields: [
        { label: "product", key: "product" },
        { label: "qty", key: "qty" },
        { label: "unit price", key: "unitPrice" },
      ],
      uploading: false,
      invList: [
        "https://cdn.cloudious.net/file-1705393078591-358356124.jpg",
        "https://cdn.cloudious.net/file-1705393109155-782170472.png",
      ],
      invDocs: "",
      itemLoading: false,
      pItemsList: [],
      pItemsDocs: "",
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getVendor(id) {
      let obj = this.vendors.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg|mp4)$/i.test(url);
    },
    print() {
      let url =
        `https://${this.reportDomain}.myskool.app/Inventory/Order?dbb=` +
        this.$store.state.userData.db +
        "&cID=" +
        this.$store.state.userData.cId +
        "&reqID=" +
        this.reqItem.id +
        "&userID=" +
        this.$store.state.userData.userID;

      window.open(url, "_blank");
    },
    openSupp() {
      this.visibility5 = true;

      this.sObj = {
        id: 0,
        name: "",
        contact: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["sname"];
      elem.state = undefined;
    },
    editSupp(item) {
      this.sObj = { ...item };
      var elem = this.$refs["sname"];
      elem.state = undefined;
    },
    checkSuppName() {
      var elem = this.$refs["sname"];
      if (this.sObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSupp() {
      if (this.checkSuppName() == true) {
        this.savingSupp = true;
        if (this.sObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "posSupplier?db=" +
              this.$store.state.userData.db,
            body: this.sObj,
            message: "Vendor added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "posSupplier/" +
              this.sObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Vendor updated successfully!",
            context: this,
            body: this.sObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.$emit("load-supp");
          var elem = this.$refs["sname"];
          elem.state = undefined;
          this.sObj = {
            id: 0,
            name: "",
            contact: "",
            campusID: this.$store.state.userData.cId,
          };
        }
        this.savingSupp = false;
      }
    },
    async deleteSupp(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "posSupplier/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Vendor removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.$emit("load-supp");
      }
    },

    PsampleAdd() {
      if (this.$refs.psamplefile.files.length !== 0) {
        this.mydocloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append("file", this.$refs.psamplefile.files[i]);
        }

        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.attachList.push(el.path);
            });
            this.qObj.attachments = this.attachList.join(",");
            this.mydocloading = false;
          })
          .catch((error) => {
            this.mydocloading = false;
            console.log(error);
          })
          .finally(() => (this.mydocloading = false));
      }
    },
    deleteDocument(index) {
      this.attachList.splice(index, 1);
      this.qObj.attachments = this.attachList.join(",");
    },
    uploadInv() {
      if (this.$refs.inv.files.length !== 0) {
        this.uploading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.inv.files.length; i++) {
          formdata.append("file", this.$refs.inv.files[i]);
        }

        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.invList.push(el.path);
            });
            this.invDocs = this.invList.join(",");
            this.uploading = false;
          })
          .catch((error) => {
            this.uploading = false;
            console.log(error);
          })
          .finally(() => (this.uploading = false));
      }
    },
    deleteInv(index) {
      this.invList.splice(index, 1);
      this.invDocs = this.invList.join(",");
    },
    uploadPItems() {
      if (this.$refs.pItems.files.length !== 0) {
        this.itemLoading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.pItems.files.length; i++) {
          formdata.append("file", this.$refs.pItems.files[i]);
        }

        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.pItemsList.push(el.path);
            });
            this.pItemsDocs = this.pItemsList.join(",");
            this.itemLoading = false;
          })
          .catch((error) => {
            this.itemLoading = false;
            console.log(error);
          })
          .finally(() => (this.itemLoading = false));
      }
    },
    deletePItems(index) {
      this.pItemsList.splice(index, 1);
      this.pItemsDocs = this.pItemsList.join(",");
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    openQuo(id) {
      if (id == 0) {
        this.qObj = {
          id: 0,
          requisitionID: this.reqItem.id,
          vendorID: 0,
          date: new Date().toJSON(),
          amount: 0,
          approvedBy: 0,
          approvalNote: "",
          attachments: "",
          note: "",
          status: "pending",
          campusID: this.$store.state.userData.cId,
        };
        this.attachList = [];
        this.qList = this.groupItems.map((el) => ({
          reqID: el.id,
          productID: el.productID,
          qty: 1,
          unitPrice: 0,
        }));
        this.qList.unshift({
          reqID: this.reqItem.id,
          productID: this.reqItem.productID,
          qty: 1,
          unitPrice: 0,
        });
      } else {
        this.qObj = { ...id };
        if (this.qObj.attachments)
          this.attachList = this.qObj.attachments.split(",");
        else this.attachList = [];

        this.qList = []; // later by api or may include only 1 item
        this.qList.unshift({
          reqID: this.reqItem.id,
          productID: this.reqItem.productID,
          qty: 1,
          unitPrice: 0,
        });
      }

      this.visibility3 = true;
      var elem = this.$refs["vID"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
      var elem = this.$refs["amount"];
      elem.state = undefined;
    },
    CheckVendor() {
      var elem = this.$refs["vID"];
      if (this.qObj.vendorID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.qObj.date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      var elem = this.$refs["amount"];
      let x = parseInt(this.qObj.amount);
      if (isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        this.qObj.amount = x;
        return (elem.state = true);
      }
    },
    CheckValues() {
      let state = true;
      let allZero = this.qList.every(
        (el) => parseInt(el.qty) == 0 || isNaN(parseInt(el.qty))
      );
      if (allZero) state = false;
      else {
        this.qList.forEach((el) => {
          let x = parseInt(el.qty);
          let y = parseInt(el.unitPrice);
          if (isNaN(x) || x < 0 || isNaN(y) || y < 0) {
            state = false;
          } else {
            el.qty = x;
            el.unitPrice = y;
          }
        });
      }
      return state;
    },
    async saveQuo() {
      console.log(this.CheckValues(), this.qList);
      return;
      this.CheckVendor();
      this.CheckDate();
      this.CheckAmount();
      if (
        this.CheckVendor() == true &&
        this.CheckDate() == true &&
        this.CheckAmount() == true
      ) {
        this.savingQ = true;

        // console.log(this.qObj);
        if (this.qObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Quotations?db=" +
              this.$store.state.userData.db,
            body: this.qObj,
            message: "Quotation added successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) {
            this.LoadData();
            this.$emit("load-data");
            this.visibility3 = false;
          }
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Quotations/" +
              this.qObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Quotation updated successfully!",
            context: this,
            body: this.qObj,
            token: this.$store.state.userData.token,
          });
          if (status) {
            this.LoadData();
            this.visibility3 = false;
          }
        }
        this.savingQ = false;
      }
    },
    async DeleteQ(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Quotations/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Quotation removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.$emit("load-data");
          this.LoadData();
        }
      }
    },
    openQApprove(item) {
      this.qDetails = { ...item };
      this.visibility4 = true;

      var elem = this.$refs["q_app"];
      elem.state = undefined;
    },
    CheckNote() {
      var elem = this.$refs["q_app"];
      if (this.qDetails.approvalNote == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async approveQ() {
      if (this.CheckNote() == true) {
        this.request = true;
        this.qDetails.approvedBy = this.$store.state.userData.userID;
        this.qDetails.status = "approved";

        var status = await this.put({
          url:
            this.$store.state.domain +
            "Quotations/" +
            this.qDetails.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quotation approved successfully!",
          context: this,
          body: this.qDetails,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData();
          this.visibility4 = false;
        }
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "requisition/LoadQuotation?id=" +
          this.reqItem.id +
          "&db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      this.dataLoading = false;
      // console.log(this.items);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.border-dashed {
  border: 1px solid var(--primary);
  border-style: dashed;
}
</style>
